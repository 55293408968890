import React from 'react';

// const xml = "<ssoResponse>" +
//     "<subscriberdata>" +
//     "<customer>" +
//     "<msisdn>436608450796</msisdn>" +
//     "<email>testmail@test.com</email>" +
//     "<salutation>HERR</salutation>" +
//     "<firstName>Anthony</firstName>" +
//     "<lastName>Stark</lastName>" +
//     "<companyName>ABC Pvt Ltd.</companyName>" +
//     "<address>" +
//     "<street1>Brünner Straße</street1>" +
//     "<street2>52</street2>" +
//     "<city>Wien</city>" +
//     "<zipCode>1210</zipCode>" +
//     "</address>" +
//     "</customer>" +
//     "<item>" +
//     "<description>iPhone 7 128GB Gold</description>" +
//     "<imei>333666333666333</imei>" +
//     "<manufacturerPartNumber>MN942ZD/A</manufacturerPartNumber>" +
//     "<priceGross>400.0</priceGross>" +
//     "<purchaseDate>2020-11-16</purchaseDate>" +
//     "<storeId>33606</storeId>" +
//     "<salesRepId>432HG76</salesRepId>" +
//     "<dreiOrderId>COM000099604524</dreiOrderId>" +
//     "<stockCode>KBX8422</stockCode>" +
//     "</item>" +
//     "</subscriberdata>" +
//     "<status>" +
//     "<statusCode>0</statusCode>" +
//     "<statusDescription>token valid</statusDescription>" +
//     "</status>" +
//     "</ssoResponse>"

const getElementByTag = (parsedXML, tagName) => {
    return parsedXML.getElementsByTagName(tagName)[0] && parsedXML.getElementsByTagName(tagName)[0].innerHTML
}

// const xmlParser = new DOMParser();
// const parsedXML = xmlParser.parseFromString(xml, 'text/xml');
// console.log("xml", parsedXML);

  
  //export const getParsedXMLObj = () => { // For local 
    export const getParsedXMLObj = (xml) => {
    const xmlParser = new DOMParser();
    const parsedXML = xmlParser.parseFromString(xml, 'text/xml');

    if (parsedXML.getElementsByTagName('statusCode')[0] && parsedXML.getElementsByTagName('statusCode')[0].innerHTML === '0') {
        const parseXMLObj = {
            isValidXML: true, // used for our own validation purpose
            salesRepId: getElementByTag(parsedXML, 'salesRepId'),
            dreiOrderId: getElementByTag(parsedXML, 'dreiOrderId'),
            deviceImei: getElementByTag(parsedXML, 'imei'),
            customerPhoneNo: getElementByTag(parsedXML, 'msisdn'),
            phoneDescription: getElementByTag(parsedXML, 'description'),
            kbxCode: getElementByTag(parsedXML, 'stockCode'),
            deviceActivationDate: getElementByTag(parsedXML, 'purchaseDate'),
            deviceRrp: getElementByTag(parsedXML, 'priceGross'),
            storeId: getElementByTag(parsedXML, 'storeId'),
            customerFirstName: getElementByTag(parsedXML, 'firstName'),
            customerLastName: getElementByTag(parsedXML, 'lastName'),
            customerSecondaryPhoneNo: getElementByTag(parsedXML, 'secondaryPhoneNo'),
            customerEmail: getElementByTag(parsedXML, 'email'),
            companyName: getElementByTag(parsedXML, 'companyName'),
            street1: getElementByTag(parsedXML, 'street1'),
            street2: getElementByTag(parsedXML, 'street2'),
            city: getElementByTag(parsedXML, 'city'),
            zipCode: getElementByTag(parsedXML, 'zipCode'),
            deviceMake: getElementByTag(parsedXML, 'make'),
            deviceModel: getElementByTag(parsedXML, 'model')
        }
        return parseXMLObj;
    }
    else {
        console.log("token not valid");
        return {
            isValidXML: false
        }
    }
}

export const checkMandatoryFields = (xmlObj) => {
    //need to match keys with xml keys for confirmation
    const mandatoryFields = ['customerPhoneNo', 'deviceImei', 'deviceRrp', 'deviceActivationDate', 'kbxCode'];
    let flag = true;
    mandatoryFields.forEach(key => {
        if (!xmlObj[key]) {
            console.log("mandatory key not found in xml", key);
            flag = false;
        }
    })
    if(xmlObj['deviceImei'] === 'NA'){
        flag = xmlObj['dreiOrderId'] ? true : false;
    } 
    return flag;
}